/* NAVBAR STYLING */

* {
    margin: 0;
}

/*.link-button {
    background-color: blue;
    color: white;
    padding: 10px;
    border-radius: 5px;
    text-decoration: none;
}

nav {
    background-color: #333;
    padding: 10px 0;
    padding: 20px;
    font-size: 20px;
    font-weight: 600;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: center;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #fff;
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

a:hover {
    background-color: #555;
}

@media only screen and (max-width: 600px) {
    nav {
        padding: 5px 0;
    }

    li {
        display: block;
        margin: 10px 0;
    }
}*/

/* src/components/Navbar.css */
.link-button {
    background-color: blue;
    color: white;
    padding: 10px;
    border-radius: 5px;
    text-decoration: none;
}

nav {
    background-color: #333;

    padding: 10px 0;
    padding: 15px;
    font-size: 20px;
    font-weight: 600;
    
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    color: white;
    padding: 10px 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.nav-toggle {
    display: none;
    flex-direction: column;
    cursor: pointer;
    position: absolute;
    top: 0.5rem;
    right: 1rem;
}

.nav-toggle span {
    background: #fff;
    height: 2px;
    /* Höhe des Hamburger-Symbols */
    width: 20px;
    /* Breite des Hamburger-Symbols */
    margin: 2px 0;
    /* Abstand zwischen den Balken */
    transition: 0.3s;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

li {
    margin: 0.5rem;
}

a {
    color: #fff;
    text-decoration: none;

    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

a:hover {
    /*text-decoration: underline;*/
    background-color: #555;
}

.nav-menu {
    display: flex;
}

/* Mobile styles */
@media (max-width: 600px) {
    nav {
        min-height: 18px;

        color: #fff;
        padding: 0.5rem 1rem;
        position: relative;
        font-size: 16px;
    }

    .nav-menu {
        display: none;
        flex-direction: column;
        width: 100%;
        background-color: #333;
    }

    .nav-menu.active {
        display: flex;
    }

    .nav-toggle {
        display: flex;
    }

    ul {
        flex-direction: column;
        align-items: center;
    }

    li {
        margin: 0.5rem 0;
    }

    a:hover {
        background-color: rgba(0, 0, 0, 0);
    }
}

/* FOOTER STYKLING */
.footer {
    background-color: #2c3e50;
    background-color: #000000;
    color: #ecf0f1;
    padding: 20px 0;
    text-align: center;
    width: 100%;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.5);
    position: relative;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.footer-text {
    font-size: 0.9em;
}

.footer-links {
    display: flex;
    gap: 15px;
}

.footer-link {
    color: #ecf0f1;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-link.disabled {
    pointer-events: none; /* Deaktiviert die Mausklicks */
    color: gray; /* Optional: Färbt den Text grau */
    text-decoration: none; /* Optional: Entfernt die Unterstreichung */
}

.footer-link:hover {
    color: #1abc9c;
}

.footer-infotext {
    color: #fff;
}

/* Mobile styles */
@media (max-width: 600px) {
    .footer-content {
        flex-direction: column;
        text-align: center;
    }

    .footer-links {
        margin-top: 10px;
    }
    
    .footer {
        padding: 15px;
        font-size: 80%;
    }
}
