.confirm-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it appears above other content */
  }
  
  .confirm-dialog {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 300px;
    max-width: 90%;
  }
  
  .confirm-dialog p {
    font-size: 16px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .confirm-dialog button {
    background: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .confirm-dialog button:hover {
    background: #0056b3;
  }
  
  .confirm-dialog button.cancel {
    background: #6c757d;
  }
  
  .confirm-dialog button.cancel:hover {
    background: #5a6268;
  }
  
button {
  display: block;
  width: 100%;
  padding: 10px;
}