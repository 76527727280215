@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&display=swap');

/* Card.css */
.card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.card {
    font-family: "Kalam", cursive;
    color: blue;
    perspective: 1000px;
    width: 50vb;
    /* Breite der Karte */
    height: 27vh;
    /* Höhe der Karte */
}

.card-inner {
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    position: relative;
}

.card.flipped .card-inner {
    transform: rotateY(180deg);
}

.card-front,
.card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 8px;
    /* Abgerundete Ecken für die Karte */
    border: 2px solid #e74c3c;
    /* Rote Linie für den Titelbereich */
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* Schatten für einen Karteneffekt */
    overflow: hidden;
}

.card-title {
    position: absolute;
    top: 10px;
    left: 20px;
    right: 20px;
    font-size: 1.4em;
    font-weight: bold;
    color: #e74c3c;
    background: #fff;
    /* Hintergrund für den Titelbereich */
    padding: 0 5px;
    border-bottom: 2px solid #e74c3c;
}

/* Card.css */
.card-content {
    margin-top: 50px; /* Platz für den Titelbereich */
    font-size: 1.2em;
    line-height: 1.25;
    padding: 0px 10px;
    height: 100%;
    position: relative;
    background: linear-gradient(to bottom, 
      #000 1px, /* Höhe der roten Linie */
      transparent 1px, 
      transparent 24px /* Abstand nach der ersten Linie */
    );
    background-size: 100% 24px; /* Hintergrundgröße, um die Linien wiederholen zu können */
  }
  
  .card-content > div {
    border-bottom: 1px solid #ccc; /* Linie unter jedem Textblock */
    padding-bottom: 0px; /* Abstand zwischen Zeilen */
  }
  
  .card-content > div:first-child {
    margin-top: -20px; /* Platz über der ersten Zeile */
  }
  
  .card-content > div:last-child {
    border-bottom: none; /* Keine Linie unter dem letzten Textblock */
  }
  

.card-back {
    transform: rotateY(180deg);
}
