/* Container für die LearnCards-Seite */
.learn-cards-page {
    font-family: 'Arial', sans-serif;
    text-align: center;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

/* Stil für den Titel */
.learn-cards-page h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    color: #333;
}

.learn-cards-page h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #555;
}

/* Stil für die Kartencontainer */
.card-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

/* Stil für die Navigationsbuttons */
.navigation-buttons {
    display: flex;
    justify-content: center;
    gap: 15px;
}

.navigation-buttons button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 12px 24px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s, box-shadow 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.navigation-buttons button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    box-shadow: none;
}

.navigation-buttons button:not(:disabled):hover {
    background-color: #0056b3;
}

.navigation-buttons button:not(:disabled):active {
    transform: scale(0.98);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
