.completed {
    background-color: rgb(0, 170, 0);
}

.overdue {
    background-color: rgb(120, 0, 0);
    color: rgb(240, 240, 240);
}

.due-soon {
    background-color: rgb(255, 166, 0);
}

.pending {
    background-color: rgb(240, 0, 0);
}

.today {
    background-color: rgb(185, 185, 185);
}



/* Homework.css */
.sortable-header {
    cursor: pointer;
    position: relative;
}

.sortable-header .sort-arrow {
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000;
    margin-left: 5px;
}

.sortable-header.desc .sort-arrow {
    border-top: none;
    border-bottom: 5px solid #000;
}

button {
    padding: 6px 8px;
    margin-right: 4px;    /* Fügt einen Abstand zwischen den Buttons hinzu */
}

button:last-child {
    margin-right: 0;       /* Entfernt den Abstand beim letzten Button */
}

.icon {
    font-size: 20px;
}

td.action {
    display: flex;         /* Macht die Zelle zu einem Flex-Container */
    align-items: center;   /* Zentriert die Buttons vertikal */
}

.pin-icon {
  transform: rotate(45deg) translate(0px, 3px) scale(1.0, 1.2);
  display: inline-block; /* Um sicherzustellen, dass der Transform funktioniert */
}


table th,
table td {
    word-wrap: break-word;
}

@media (max-width: 768px) {
    .homework-container {
        padding: 10px;
        overflow-x: auto;
    }

    .table-container {
        overflow-x: auto;
    }

    table {
        font-size: 14px;
        border-collapse: collapse;
    }

    .filter-container input,
    .filter-container select {
        margin: 5px 0;
        width: 100%;
        box-sizing: border-box;
        font-size: 14px;
    }

    .reg-button,
    .complete-button,
    .not-complete-button,
    .delete-button {
        font-size: 14px;
        padding: 5px;
    }

    /* Erledigt und Actions Spalten ausblenden */
    .hide-on-mobile {
        display: none !important;
    }
}